import React from 'react'

export default function Footer() {
  return (
    <footer className="py-8 mt-8 border-t border-slate-800">
      <div className="container mx-auto px-4">
        <div className="text-center text-slate-400">
          <p className="text-white font-medium">
            &copy; {new Date().getFullYear()}{" "} All rights reserved |
            Made with ❤️ by{" "}
            <a
              href="/"
              className="text-[#19A7CE] hover:text-[#fff] transition duration-300"
            >
              Pradip Bhattarai
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

