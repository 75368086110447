import { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import Hero from "../components/hero";
import Header from "../components/header";
import About from "../components/about";
import Experience from "../components/experience";
import Footer from "../components/footer";
import Education from "../components/education";
import Certifications from "../components/certifications";
import Projects from "../components/projects";

// initialize firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAjvTSUYtslulC-4GjEdhxr0Y7lB12vDwo",
  authDomain: "prdp-portfolio.firebaseapp.com",
  projectId: "prdp-portfolio",
  storageBucket: "prdp-portfolio.firebasestorage.app",
  messagingSenderId: "913941526176",
  appId: "1:913941526176:web:e1702a3c7caf459157bbc5",
  measurementId: "G-D4ELQE83LP"
};import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://1e5eeab0f200e5fa40577c11a1613e43@o4508840435318784.ingest.us.sentry.io/4508840442265600",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
});

function App() {
  const [user, setUser] = useState({});
  const githubUsername = "prdp1137";

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  // 

  const fetchData = async () => {
    const userResponse = await fetch(
      `https://api.github.com/users/${githubUsername}`
    );
    const userData = await userResponse.json();
    setUser(userData);
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-950 via-slate-900 to-slate-950">
      <Header />
      <main className="container mx-auto px-4 py-16 space-y-32">
        <Hero user={user} />
        <About />
        <Experience />
        <Education />
        <Certifications />
        <Projects />
      </main>
      <Footer />
    </div>
  );
}

export default App;
