"use client"

import React from "react"
import { motion } from "framer-motion"
import { GraduationCap } from 'lucide-react'

export default function Education() {
  return (
    <section id="education" className="relative mt-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="space-y-12"
      >
        <div className="">
          <h2 className="text-3xl text-white font-bold mb-4">Education</h2>
          <p className="text-slate-400">A brief overview of my educational background.</p>
        </div>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="relative bg-slate-800/50 rounded-2xl p-8 border border-slate-700"
        >
          <div className="flex flex-col md:flex-row gap-6 md:gap-12">
            <div className="md:w-1/4">
              <img
                src="https://www.londonmet.ac.uk/favicons/favicon.ico"
                alt="University Logo"
                width={60}
                height={60}
                className="rounded-lg mb-4"
              />
              <p className="text-slate-400">2019 - 2022</p>
            </div>
            <div className="md:w-3/4">
              <div className="text-xl font-semibold flex items-center">
                <GraduationCap className="w-5 h-5 text-cyan-400 mr-2" />
                <span className="text-white">BSc (Hons) Computer Networking and IT Security</span>
              </div>
              <p className="text-cyan-400 mt-1">London Metropolitan University</p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  )
}
