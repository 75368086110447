"use client"

import React from "react"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <motion.header
      className={`fixed top-0 left-0 right-0 z-50 transition-colors duration-300 ${
        isScrolled ? "bg-slate-950/80 backdrop-blur-md border-b border-slate-800" : ""
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <a
            href="/"
            className="text-xl font-bold bg-gradient-to-r from-cyan-400 to-cyan-200 bg-clip-text text-transparent"
          >
            Pradip Bhattarai
          </a>
          <nav className="hidden md:flex items-center gap-8">
            <a href="#about" className="text-slate-300 hover:text-white transition-colors">
              About
            </a>
            <a href="#experience" className="text-slate-300 hover:text-white transition-colors">
              Experience
            </a>
            <a
              href="mailto:contact@pradeepbhattarai.me"
              className="px-4 py-2 rounded-full bg-cyan-500 hover:bg-cyan-400 text-white transition-colors"
            >
              Contact me
            </a>
          </nav>
        </div>
      </div>
    </motion.header>
  )
}

