"use client"

import React from "react"
import { motion } from "framer-motion"
import { Award } from 'lucide-react'
// import img from "next/img"

const certifications = [
    {
        id: 1,
        name: "Certified Cloud Pentesting eXpert - AWS (CCPenX-AWS)",
        issuer: "The SecOps Group",
        issueDate: "Jan 2025",
        expires: "Never",
        credentialId: "9561565",
        credentialUrl: "https://www.youracclaim.com/badges/1b9f7e6b-4a6d-4b1b-9b1f-4f0b2c2b6d7e",
        issuerLogo: "https://secops.group/wp-content/uploads/2022/10/favicon.png",
    },
    {
        id: 1,
        name: "Certified Ethical Hacker (CEH) | Practical",
        issuer: "EC-Council",
        issueDate: "2021",
        expires: "Present",
        credentialId: "ECC104314",
        credentialUrl: "https://www.youracclaim.com/badges/1b9f7e6b-4a6d-4b1b-9b1f-4f0b2c2b6d7e",
        issuerLogo: "https://e7.pngegg.com/pngimages/768/788/png-clipart-ec-council-certified-ethical-hacker-computer-security-information-security-certified-ethical-hacker-emblem-label.png",
    },
    {
        id: 2,
        name: "Certified Network Pentester - (CNPen)",
        issuer: "The SecOps Group",
        issueDate: "Jan 2025",
        expires: "Never",
        credentialId: "9558741",
        credentialUrl: "https://www.youracclaim.com/badges/1b9f7e6b-4a6d-4b1b-9b1f-4f0b2c2b6d7e",
        issuerLogo: "https://secops.group/wp-content/uploads/2022/10/favicon.png",
    },
    {
        id: 2,
        name: "Network Security Expert: NSE 1",
        issuer: "Fortinet",
        issueDate: "2020",
        expires: "2022",
        credentialId: "Ei7TDKyK5t",
        credentialUrl: "https://training.fortinet.com/badges/badge.php?hash=b1e8f5236278a3897f3c22aac40a0f399f011fb1",
        issuerLogo: "https://images.cakeresume.com/post-images/93123405-d1e2-4e5d-8075-28b835936917.png",
    },
    {
        id: 3,
        name: "Network Security Expert: NSE 2",
        issuer: "Fortinet",
        issueDate: "2020",
        expires: "2022",
        credentialId: "H7n4qJw15g",
        credentialUrl: "https://training.fortinet.com/badges/badge.php?hash=d70d36697eca29e75cb39a030ba33bf318455009",
        issuerLogo: "https://images.cakeresume.com/post-images/37dd54a9-426c-4c74-acb7-9084d665ec2e.png",
    },
    {
        id: 4,
        name: "Network Security Expert: NSE 3",
        issuer: "Fortinet",
        issueDate: "2022",
        expires: "2024",
        credentialId: "0dNIYp6WCL",
        credentialUrl: "https://training.fortinet.com/badges/badge.php?hash=038067b502bbe6f9bec5e898bc9110f56e11782f",
        issuerLogo: "https://images.cakeresume.com/post-images/92a96c5c-09fd-4563-8592-d2aa64b0aa9a.png",
    },
    {
        id: 5,
        name: "Autopsy Basics and Hands-On",
        issuer: "Autopsy",
        issueDate: "2021",
        expires: "Never",
        credentialId: "pdbkyrtj1u",
        credentialUrl: "https://dfir-training.basistech.com/certificates/pdbkyrtj1u",
        issuerLogo: "https://www.kali.org/tools/autopsy/images/autopsy-logo.svg",
    },
    {
        id: 6,
        name: "Multi-Cloud Red Teaming Analyst",
        issuer: "CyberWarFare Labs",
        issueDate: "2024",
        expires: "Never",
        credentialId: "65ed714135a23778010d9365",
        credentialUrl: "https://www.cyberwarfarelabs.com/",
        issuerLogo: "https://cyberwarfare.live/wp-content/uploads/2023/04/cwl_logo-e1706188716155.jpg",
    },
    {
        id: 7,
        name: "Certified AppSec Practitioner",
        issuer: "The SecOps Group",
        issueDate: "2023",
        expires: "Never",
        credentialId: "8079987",
        credentialUrl: "https://www.youracclaim.com/badges/1b9f7e6b-4a6d-4b1b-9b1f-4f0b2c2b6d7e",
        issuerLogo: "https://secops.group/wp-content/uploads/2022/10/favicon.png",
    }
]

export default function Certifications() {
    return (
        <section id="education" className="relative mt-8">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="space-y-12"
            >

                <div className="mb-8">
                    <h2 className="text-3xl text-white font-bold mb-4">Certifications</h2>
                    <p className="text-slate-400">A collection of my professional achievements.</p>
                </div>
            </motion.div>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                {certifications.map((cert, index) => (

                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true }}
                        className="relative bg-slate-800/50 rounded-2xl p-1 border border-slate-700"
                        key={index}
                    >
                        <div
                            className="relative backdrop-blur-md border-white/20 
                            p-6 rounded-xl transition-all transform 
                            group-hover:scale-105 group-hover:shadow-cyan-400/40"
                        >
                            <div
                                className="absolute -inset-1 rounded-xl opacity-0 group-hover:opacity-100 
                                transition-opacity duration-300"
                            />

                            <div className="relative flex items-center gap-4">
                                <img
                                    src={cert.issuerLogo || "/placeholder.svg"}
                                    alt={cert.issuer}
                                    className="h-16 w-16"
                                />

                                <div>
                                    <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                                        {/* <Award className="w-5 h-5 text-cyan-400" /> */}
                                        {cert.name}
                                    </h3>
                                    <p className="text-white text-lg">{cert.issuer}</p>
                                    <p className="text-white text-sm my-2">
                                        ID: {cert.credentialId}
                                    </p>
                                    <p className="text-white text-xs">
                                        {cert.issueDate} - {cert.expires}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </section>
    );
}
