"use client"

import React from "react"
import { motion } from "framer-motion"
import { Calendar, MapPin } from "lucide-react"

const experiences = [
    {
        title: "Security Research Engineer / Penetration Tester",
        company: "CryptoGen Nepal Pvt. Ltd.",
        date: "July 2021 - December 2024",
        location: "Kathmandu, Nepal",
        description: [
            "Conduct Vulnerability Assessment and Penetration Testing on web, mobile, and network environments.",
            "Manage cloud infrastructure and Active Directory environments.",
            "Designed and structured cybersecurity product architectures.",
            "Explored emerging cybersecurity technologies and threats.",
            "Assessed the practicality and effectiveness of security solutions.",
            "Worked with cross-functional teams for product development.",
            "Maintained clear and detailed documentation.",
            "Developed prototypes and key contribution to multiple in-house projects.",
            "Continuously improve existing solutions by identifying optimizations and enhancements.",
        ],
        companyLogo: "https://raw.githubusercontent.com/SayCure/saycure.github.io/main/assets/img/CGN.png",
    },
];

export default function Experience() {
    return (
        <section id="experience" className="relative mt-8">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="space-y-12"
            >
                <div className="">
                    <h2 className="text-3xl font-bold mb-4 text-white">Experience</h2>
                    <p className="text-slate-400">A brief overview of my professional experience and journey in cybersecurity.</p>
                </div>
                <div className="space-y-12">
                    {experiences.map((experience, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            viewport={{ once: true }}
                            className="relative bg-slate-800/50 rounded-2xl p-8 border border-slate-700"
                        >
                            <div className="flex flex-col md:flex-row gap-6 md:gap-12">
                                <div className="md:w-1/3">
                                    <img src={experience.companyLogo} alt={experience.company} className="w-32 mb-4" />
                                    <h3 className="text-xl text-white font-semibold">{experience.title}</h3>
                                    <p className="text-cyan-400 mt-1">{experience.company}</p>
                                    <div className="flex items-center gap-4 mt-4 text-sm text-slate-400">
                                        <div className="flex items-center gap-1">
                                            <Calendar className="w-4 h-4" />
                                            <span>{experience.date}</span>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <MapPin className="w-4 h-4" />
                                            <span>{experience.location}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-2/3">
                                    <ul className="text-slate-300 list-disc list-inside space-y-2 text-justify">
                                        {experience.description.map((desc, index) => (
                                            <li key={index}>{desc}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        </section>
    )
}

