"use client"

import React from "react"
import { motion } from "framer-motion"
import { Github, Linkedin, Twitter } from "lucide-react"

export default function Hero({user}) {
  return (
    <section className="min-h-screen flex items-center justify-center">
      <div className="text-center space-y-8">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
          className="relative w-40 h-40 mx-auto mb-8"
        >
          <img
            src={user.avatar_url}
            alt="Profile"
            className="rounded-full object-cover border-4 border-cyan-500/20"
          />
        </motion.div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-4xl md:text-6xl font-bold bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent"
        >
          Hi, I'm Pradip Bhattarai 👋
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-lg md:text-xl text-slate-400 max-w-2xl mx-auto"
        >
          Security Researcher
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-center gap-6"
        >
          <a
            href="https://github.com/prdp1137"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-full bg-slate-800 border hover:bg-slate-700 transition-colors text-white"
          >
            <Github className="w-6 h-6" />
          </a>
          <a
            href="https://twitter.com/__iAmPradeep"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-full bg-slate-800 border hover:bg-slate-700 transition-colors text-white"
          >
            <Twitter className="w-6 h-6" />
          </a>
          <a
            href="https://linkedin.com/in/prdp"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-full bg-slate-800 border hover:bg-slate-700 transition-colors text-white"
          >
            <Linkedin className="w-6 h-6" />
          </a>
        </motion.div>
      </div>
    </section>
  )
}

