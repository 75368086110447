"use client"

import React from "react"
import { motion } from "framer-motion"
// import Image from "next/image"

export default function About() {
  return (
    <section id="about" className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="grid md:grid-cols-2 gap-12 items-center"
      >
        <div className="space-y-6">
          <h2 className="text-3xl font-bold text-white">About Me</h2>
          <p className="text-slate-400 leading-relaxed">
            I am a Security Researcher with a passion for cybersecurity and a knack for solving complex problems.
          </p>
          <p className="text-slate-400 leading-relaxed">
            My journey into the world of penetration testing and red teaming has equipped me with a profound
            understanding of Penetration Testing, Exploitation, Social Engineering, Reconnaissance, Infrastructure
            Security, and Management.
          </p>
          <p className="text-slate-400 leading-relaxed">
            I am always open to collaboration, knowledge exchange, and discussions within the cybersecurity and tech communities. Feel free to reach out to me via <a href="mailto:contact@pradeepbhattarai.me" className="text-cyan-400 hover:text-cyan-500">
            E-mail</a>,
            <a href="https://twitter.com/__iAmPradeep" className="text-cyan-400 hover:text-cyan-500"> Twitter</a>, or
            <a href="https://www.linkedin.com/in/prdp/" className="text-cyan-400 hover:text-cyan-500"> LinkedIn</a> to connect, share ideas, or explore potential opportunities.
          </p>
          <div className="pt-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <img
                src="https://raw.githubusercontent.com/SayCure/saycure.github.io/main/assets/img/CGN.png"
                alt="CryptoGen Nepal"
                width={200}
                height={60}
                className="object-contain filter brightness-90 hover:brightness-100 transition-all"
              />
              <img
                src="https://i.imgur.com/7ss6EtO.png"
                alt="PTN"
                width={60}
                height={60}
                className="object-contain filter brightness-90 hover:brightness-100 transition-all grayscale"
              />
              <img
                src="https://owasp.org/assets/images/logo.png"
                alt="OWASP"
                width={200}
                height={60}
                className="object-contain filter brightness-90 hover:brightness-100 transition-all invert"
              />
              <img
                src="https://www.askbuddie.com/images/logo/ask-buddie.svg"
                alt="Ask Buddie"
                width={100}
                height={60}
                className="object-contain filter brightness-90 hover:brightness-100 grayscale invert transition-all"
              />
            </div>
          </div>
        </div>
        <div className="relative h-[500px] rounded-2xl overflow-hidden">
          <img src="/nullcon-ctf.png" alt="About" className="object-cover w-full grayscale" />
          <div className="absolute inset-0 bg-gradient-to-t from-slate-950 to-transparent" />
        </div>
      </motion.div>
    </section>
  )
}

