"use client";

import React from "react";
import { motion } from "framer-motion";
import { FolderOpen, Globe, Code } from "lucide-react";

const userRepo = [
    {
        id: 5,
        name: "O365-Enum",
        html_url: "https://github.com/prdp1137/O365-Enum",
        description: "O365-Enum is a script that allows you to perform username enumeration against Office 365 using AutoDiscover endpoints.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    },
    {
        id: 2,
        name: "huntPastebin",
        html_url: "https://github.com/prdp1137/huntPastebin",
        description: "huntPastebin is a command-line tool that allows you to search for leaked information on pastebin. It supports searching for domains, emails, or performing a general search.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    },
    {
        id: 1,
        name: "vnccrack",
        html_url: "https://github.com/prdp1137/vnccrack",
        description: "This script allows to bruteforce a VNC server using a list of passwords.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    },
    {
        id: 3,
        name: "PortScanner",
        html_url: "https://github.com/prdp1137/portscanner",
        description: "A simple port scanner written in Python.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    },
    {
        id: 4,
        name: "ftpbruteforce",
        html_url: "https://github.com/prdp1137/ftpbruteforce",
        description: "A simple FTP bruteforce tool written in Python.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    },
    {
        id: 6,
        name: "SSH Honeypot",
        html_url: "https://github.com/prdp1137/SSH-Honeypot",
        description: "A simple SSH honeypot written in Python.",
        owner: {
            login: "prdp1137",
            avatar_url: "https://avatars.githubusercontent.com/u/29733866?v=4",
        },
        language: "Python",
    }
];

export default function Projects() {
    return (
        <section id="projects" className="relative mt-8">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="space-y-12"
            >
                <div className="">
                    <h2 className="text-3xl text-white font-bold mb-4">Projects</h2>
                    <p className="text-slate-400">A collection of side projects that I have built.</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                    {userRepo.map((repo, index) => (
                        <motion.div
                            key={repo.id}
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            viewport={{ once: true }}
                            className="relative bg-slate-800/50 rounded-2xl p-4 border border-slate-700"
                        >
                            <a
                                href={repo.html_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative block"
                            >
                                <div className="absolute -inset-1 rounded-2xl opacity-0 group-hover:opacity-100 
                                transition-opacity duration-300 blur-xl bg-gradient-to-r from-cyan-400/30 
                                to-blue-500/30"
                                />
                                <div className="flex justify-between items-center">
                                    <h3 className="text-lg font-bold text-white flex items-center gap-2">
                                        <FolderOpen className="w-5 h-5 text-cyan-400" />
                                        {repo.name.replaceAll("-", " ")}
                                    </h3>
                                    <img
                                        src={repo.owner.avatar_url}
                                        alt="Owner Avatar"
                                        height={40}
                                        width={40}
                                        className="rounded-full shadow-lg"
                                    />
                                </div>
                                <p className="mt-2 text-sm text-white truncate">
                                    {repo.description || "No description available."}
                                </p>
                                <div className="mt-4 flex items-center justify-between">
                                    <div className="flex items-center bg-slate-500/20 border-[#19A7CE] rounded-xl p-2 space-x-1">
                                        <Code className="w-4 h-4 text-white" />
                                        <span className="text-sm text-white">{repo.language || "Unknown"}</span>
                                    </div>
                                    <div className="flex items-center bg-slate-500/20 border-[#19A7CE] rounded-xl p-2 space-x-1">
                                        <Globe className="w-4 h-4 text-white" />
                                        <span className="text-sm text-white">View on GitHub</span>
                                    </div>
                                </div>
                            </a>
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        </section>
    );
}